body{
    display: flex;
    min-height: 100vh;
    background-image: linear-gradient(210deg, #51aff7 0%, #abe8fa 100%);
}

.product-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.article-container{
    position: absolute;
    top: 30%;
    left: 25%;
}
.glassStyle {
	background-image: linear-gradient(
		133deg,
		rgba(255, 255, 255, 0.4) 0%,
		rgba(255, 255, 255, 0.1) 95%
	);
	border: 0 solid #ffffff;
	box-shadow: 9px 5px 95px 11px rgb(0 0 0 / 50%);
	margin: 40px;
	border-radius: 40px;
}
.loginField {
	border-bottom: 2px solid #656161;
    position: relative;
    margin: 64px 0px -13px 0px;
}
.loginField input{
    font-family: 'Roboto';
    font-size: 15px;
    color: rgb(48, 47, 47);
    border:none;
    width: 100%;
    outline: none;
    background: none;
    padding: 0 5px;
    height: 40px;
}
.loginField span::before{
    content: attr(data-placeholder);
    position: absolute;
    top: 50%;
    left: 5px;
    color: #656161;
    transform: translateY(-50%);
    z-index: -1;
   transition: .5s;
}
.loginField span::after{
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    background: linear-gradient(210deg, #51aff7 0%, #abe8fa 100%);
    transition: .5s;
}
.logbtn {
	font-family: 'DM Serif Display';
    color: black;
    background-image: linear-gradient(-34deg, #00B257 0%, rgba(255,255,255,0.19) 100%);
    box-shadow: 3px 1px 14px rgb(0 0 0 / 50%);
    border: 0px solid #FFFFFF;
    left: 90%;
    width: 100%;
    height: 10%;
    border-radius: 5px;
    background-size: 200%;
    cursor: pointer;
    margin-top: 109px;
    transition: .5s;
}
.addbtn {
	font-family: "Roboto";
	position: absolute;
	left: 37%;
	padding: 10px;
	background-image: linear-gradient(
		-34deg,
		#00b257 0%,
		rgba(255, 255, 255, 0.19) 100%
	);
	border: 0 solid #ffffff;
	border-radius: 10px;
	background-size: 200%;
	cursor: pointer;
}
.addbtn:hover {
	background-position: center;
}


@media screen and (max-width: 1000px) {
    .product-container {
      flex-direction: column;
    }
    .form-container {
        justify-content: space-around;
        margin-left: 140px;
        margin-top: 70px;
    }
    
  }

.shopAndCart {
    display: flex;
    flex-direction: row;
}

.cart-title {
    text-transform: uppercase;
	font-family: 'DM Serif Display';
    letter-spacing: 1px;
    color: #1976d2;
    border-bottom: 2px solid #1976d2;
    display: inline;
    padding: 8px;
    font-weight: 600;
    font-size: 16px;
}

/* @media screen and (max-width: 1800px) {
    .shopAndCart {
      flex-direction: column;
    }
}  */

